import React, {useState} from "react";
import {Link} from "react-router-dom";

import LogoText from "../components/LogoText";
import Logo from "../components/Logo";
import {Navbar} from "react-bootstrap";
import {setAuthenticationToken} from "../utils/auth_utils";

import {userServices} from "../services";
import {useDispatch} from "react-redux";
import AsyncButton from "../components/buttons/AsyncButton";

export default () => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const isLoginDisabled = () => {
        return (email === "" || password === "");
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (!isLoginDisabled()) {
                login();
            }
        }
    };

    const login = (accept, reject) => {
        userServices.authenticate(email, password)(dispatch)
            .then(res => {
                if (accept) {
                    accept();
                }
                setAuthenticationToken(res, rememberMe);
                window.location.href = "/";
            })
            .catch(err => {
                if (reject) {
                    reject(err);
                }
                setLoginError(true);
            })
    };

    return (
        <section className="auth-section login-section text-center py-5">
            <div className="container">
                <div className="site-logo mb-4">
                    <Link to={"/"}>
                        <Navbar.Brand>
                            <Logo/>
                            <LogoText/>
                        </Navbar.Brand>
                    </Link>
                </div>

                <div className="auth-wrapper mx-auto shadow p-5 rounded">

                    <h2 className="auth-heading text-center mb-5">Log Into Your Account</h2>

                    <div className="auth-form-container text-left mx-auto">
                        <div className="auth-form login-form">
                            <div className="form-group email">
                                <label className="sr-only" htmlFor="signin-email">Email</label>
                                <input id="signin-email"
                                       name="signin-email"
                                       type="email"
                                       className="form-control signin-email"
                                       placeholder="Email address"
                                       required="required"
                                       onChange={(v) => setEmail(v.target.value)}
                                />
                            </div>

                            <div className="form-group password">
                                <label className="sr-only" htmlFor="signin-password">Password</label>
                                <input id="signin-password"
                                       name="signin-password"
                                       type="password"
                                       className="form-control signin-password"
                                       placeholder="Password"
                                       onChange={(v) => setPassword(v.target.value)}
                                       onKeyDown={_handleKeyDown}
                                       required="required"/>
                                <div className="extra mt-3 row justify-content-between">
                                    <div className="col-6">
                                        <div className="checkbox remember">
                                            <label>
                                                <input type="checkbox"
                                                       className={"mr-1"}
                                                       onChange={(e) => setRememberMe(e.target.checked)}/>
                                                Remember me
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="forgot-password text-right">
                                            <Link to="/reset-password">Forgot password?</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loginError &&
                            <div className={"text-center text-danger m-3"}>
                                Invalid credentials: username and/or password don't match our archives
                            </div>
                            }
                            <div className="text-center">
                                <AsyncButton className="btn-block theme-btn mx-auto"
                                        onClick={login}
                                        disabled={isLoginDisabled()}>
                                    Log In
                                </AsyncButton>
                            </div>
                        </div>

                        <div className="auth-option text-center pt-5">
                            No Account? Sign Up <Link to="/signup">Here</Link>.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}