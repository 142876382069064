import React from 'react';
import {HashRouter as Router, Route} from "react-router-dom";
import {Switch} from "react-bootstrap";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDoubleRight,
    faArrowAltCircleRight,
    faBook,
    faDollarSign,
    faInfo,
    faShapes
} from '@fortawesome/free-solid-svg-icons'

import HomePage from "./pages/HomePage";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";

import Header from "./components/Header";

import "./css/App.css";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import SuccessfulRegistration from "./pages/SuccessfulRegistration";
import Documentation from "./pages/Documentation";
import {isAuthenticated} from "./utils/auth_utils";
import {useDispatch} from "react-redux";
import {userServices} from "./services";
import HomePageAuthenticated from "./pages/HomePageAuthenticated";
import DocsHome from "./pages/DocsHome";
import Swagger from "./pages/Swagger";
import Footer from "./components/Footer";
import ReactGA from 'react-ga';
import withTracker from "./utils/WithTracker";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import ItemMDEditor from "./pages/ItemMDEditorPage";

library.add(faArrowAltCircleRight, faBook, faInfo, faShapes, faAngleDoubleRight, faDollarSign);

const homePage = () => (
    <>
        <Header/>
        {!isAuthenticated() &&
        <HomePage/>
        }
        {isAuthenticated() &&
        <HomePageAuthenticated/>
        }
    </>
)

const docsHomePage = () => (
    <>
        <Header/>
        <DocsHome/>
    </>
)

const openApiPage = () => (
    <>
        <Header/>
        <Swagger/>
    </>
)

const docPage = () => (
    <Documentation/>
)

const featuresPage = () => (
    <>
        <Header/>
        <Features/>
    </>
)

const pricingPage = () => (
    <>
        <Header/>
        <Pricing/>
    </>
)

const editItemAsMD = () => (
    <>
        <Header/>
        <ItemMDEditor />
    </>
)
export default () => {

    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }

    const dispatch = useDispatch();

    if (isAuthenticated()) {
        userServices.me()(dispatch);
    }

    return (
        <Router>
            <Switch>
                <Route path={"/login"}>
                    <Login/>
                </Route>
                <Route path={"/signup"}>
                    <Signup/>
                </Route>
                <Route path={"/reset-password"}>
                    <ResetPassword/>
                </Route>
                <Route path={"/reset-password-confirm"}>
                    <ResetPasswordConfirm/>
                </Route>
                <Route path={"/successful-registration"} exact>
                    <SuccessfulRegistration/>
                </Route>
                <Route path={"/"}>
                    <div className={"with-header"} style={{minHeight: 500}}>
                        <Route path={"/"} exact component={withTracker(homePage)}/>
                        <Route path={"/collections/:collection/items/:item"} exact component={withTracker(editItemAsMD)}/>
                        <Route path={"/documentation"} exact component={withTracker(docsHomePage)}/>
                        <Route path={"/openapi"} component={withTracker(openApiPage)}/>
                        <Route path={"/documentation/:doc"} component={withTracker(docPage)}/>
                        <Route path={"/features"} component={withTracker(featuresPage)}/>
                        <Route path={"/pricing"} component={withTracker(pricingPage)}/>
                    </div>
                </Route>
            </Switch>
            <Footer/>
        </Router>
    );

}