import React, {useEffect, useState} from "react";
import sha256 from 'crypto-js/sha256';
import {useSelector} from "react-redux";
import AsyncButton from "./buttons/AsyncButton";
import {userServices} from "../services";
import InputHide from "./InputHide";

export default () => {

    const [apiKey, setApiKey] = useState();

    const user = useSelector(i => {
        return i.user || {};
    });

    useEffect(() => {
        setApiKey(user.api_key || "No API key set")
    }, [user]);

    const generate = (accept, reject) => {
        const newApiKey = sha256(user.storage_uuid + new Date()).toString();
        setApiKey(newApiKey);
        userServices.updateApiKey(newApiKey)()
            .then(() =>
                    accept(),
                (err) =>
                    reject(err));
    }

    return (
        <>
            <h6>
                Your API Key
            </h6>
            <small>
                <p>
                    Use this secret to access KVStore.io via REST API. By clicking the "generate" button,
                    you renew the token and the previous one becomes invalid.
                </p>
            </small>
            <InputHide className="mb-3 mt-3"
                       prepend={<AsyncButton onClick={generate} variant="primary">Generate</AsyncButton>}
                       placeholder="Your API key"
                       value={apiKey}
                       readOnly={true}/>
        </>
    );
}