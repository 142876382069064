import React, {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {Button, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import {planServices} from "../services";

import {isAuthenticated} from "../utils/auth_utils";
import PricingCard from "../components/PricingCard";
import FeatureRow from "../components/features/FeatureRow";
import Billing from "../components/billing/Billing";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";


export default () => {
    const dispatch = useDispatch();
    const [currentPlanLength, setCurrentPlanLength] = useState("month");
    const [planLength, setPlanLength] = useState("month");
    const [showBilling, setShowBilling] = useState(false);
    const [chosenPlan, setChosenPlan] = useState();

    const listOfPlans = useSelector(state => state.plans.items);
    const subscription = useSelector(state => state.plans.subscription);

    useEffect(() => {
        if (!listOfPlans) {
            planServices.list()(dispatch);
        }

        if (isAuthenticated() && !subscription) {
            planServices.mySubscription()(dispatch)
        }

        if (subscription && listOfPlans) {
            listOfPlans.forEach(loopPlan => {
                if (loopPlan.yearly_stripe_id === subscription.stripe_price_id) {
                    setPlanLength("year");
                    setCurrentPlanLength("year");
                } else if (loopPlan.monthly_stripe_id === subscription.stripe_price_id) {
                    setPlanLength("month");
                    setCurrentPlanLength("month");
                }
            })
        }
    }, [subscription, dispatch, listOfPlans]);

    const openBilling = (plan) => {
        setChosenPlan(plan);
        setShowBilling(true);
    };

    const cta = (newPlan) => {
        if (!subscription) {
            if (newPlan.plan === "FREE") {
                if (isAuthenticated()) {
                    return <Button onClick={() => openBilling(newPlan)}>Choose this</Button>
                } else {
                    return <Link to="/signup"><Button>Get Started</Button></Link>
                }
            } else {
                return <Button disabled>Upgrade later</Button>
            }
        }

        if (newPlan.plan !== subscription.plan) {
            if (newPlan.max_values >= subscription.values &&
                newPlan.max_num_collections >= subscription.collections) {
                return <Button onClick={() => openBilling(newPlan)}>Switch to this</Button>
            } else {
                return <Button disabled>Doesn't fit!</Button>
            }
        } else {
            return <Button disabled>Current ({currentPlanLength}ly)</Button>
        }
    };

    return (
        <>
            <div className="page-header theme-bg-dark py-5 text-center position-relative">
                <div className="theme-bg-shapes-right"/>
                <div className="theme-bg-shapes-left"/>
                <div className="container">
                    <h1 className="page-heading single-col-max mx-auto">
                        Simple Plans For Every Project!
                    </h1>
                    <div className="page-intro single-col-max mx-auto">
                        Start with the "FREE" plan (no credit card required)
                        and upgrade any moment...
                    </div>
                </div>
            </div>

            <div className="page-content py-5">
                <section className="plans-section mb-5 pb-5">
                    <Container>
                        <div className="plan-switch text-center mb-4" id="plan-switch">

                            <ul className="radio-switch mx-auto shadow-sm">
                                <li className="radio-switch-item">
                                    <input type="radio" className="radio-switch-input sr-only" id="radio1"
                                           data-plan-cost="plan-cost-monthly" name="radioSwitch"
                                           checked={planLength === "month"}
                                           onChange={() => setPlanLength("month")}/>
                                    <label htmlFor="radio1" className="radio-switch-label">Monthly</label>
                                </li>

                                <li className="radio-switch-item">
                                    <input type="radio" className="radio-switch-input sr-only" id="radio2"
                                           data-plan-cost="plan-cost-yearly" name="radioSwitch"
                                           checked={planLength === "year"}
                                           onChange={() => setPlanLength("year")}/>
                                    <label htmlFor="radio2" className="radio-switch-label">Yearly</label>
                                    <div aria-hidden="true" className="radio-switch-marker"></div>
                                </li>
                            </ul>
                            <div className="mb-5 plan-switch-intro">Switch to yearly plans and save!</div>

                        </div>

                        <div className="plan-items row justify-content-center" id="plan-items">
                            {listOfPlans &&
                            listOfPlans.sort((i, j) =>
                                i.order - j.order
                            ).map(plan => (
                                <div key={plan.plan} className="col-12 col-md-6 col-lg-3 mb-3">
                                    <PricingCard
                                        cta={cta(plan)}
                                        name={plan.subscription}
                                        description={plan.description}
                                        monthly={plan.monthly_eur / 100}
                                        yearly={plan.yearly_eur / 100}
                                        showPlan={planLength}>
                                        {plan.features &&
                                        plan.features.map(feature => (
                                            <FeatureRow
                                                key={feature.name}
                                                name={feature.name}
                                                description={feature.help}/>
                                        ))
                                        }
                                    </PricingCard>
                                </div>
                            ))
                            }
                        </div>
                    </Container>
                    <Container className={"py-5"}>
                        <Row className={"text-center"}>
                            <p className={"w-100"}>
                                We have a free plan reserved to no-profits. Please contact us.
                            </p>
                        </Row>
                    </Container>
                </section>
            </div>
            <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)}>
                <Billing show={showBilling}
                         onClose={() => setShowBilling(false)}
                         plan={chosenPlan}
                         planLength={planLength}
                />
            </Elements>
        </>
    );
}