import React from "react";
import Logo from "../img/logo.svg";

export default () => {

    return (
        <img alt=""
             src={Logo}
             width="30"
             height="30"
             className="logo-icon mr-2"
        />
    );
}