import React from "react";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import {Container} from "react-bootstrap";

export default () => {

    return (
        <Container>
            <SwaggerUI url={`${process.env.REACT_APP_API_BASE_URL}/v3/api-docs/`}
                       supportedSubmitMethods={["get", "put", "post", "delete"]}
            />
        </Container>
    );

}