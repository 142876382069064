import React, {useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

export default (props) => {

    const {placeholder, value, readOnly, prepend, className} = props;

    const [hide, setHide] = useState(true);

    const onChange = (e) => {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    return (
        <InputGroup className={className}>
            {prepend &&
            <InputGroup.Prepend>
                {prepend}
            </InputGroup.Prepend>
            }
            <Form.Control placeholder={placeholder}
                          defaultValue={value}
                          onChange={onChange}
                          readOnly={readOnly}
                          className={"small"}
                          type={hide ? "password" : "text"}/>
            <InputGroup.Append>
                <InputGroup.Text onClick={() => setHide(!hide)}>
                    <FontAwesomeIcon icon={hide ? faEyeSlash : faEye}/>
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    );

}