import React from "react";
import Feature from "../components/features/Feature";

import Collections from "../docs/features/collections";
import KeyValue from "../docs/features/keyvalue";
import PublicRead from "../docs/features/publicread";
import PublicWrite from "../docs/features/publicwrite";
import WebHook from "../docs/features/webhooks";
import CustomerSupport from "../docs/features/customersupport";


export default () => {
    return (
        <>
            <div className="page-header theme-bg-dark py-5 text-center position-relative">
                <div className="theme-bg-shapes-right"/>
                <div className="theme-bg-shapes-left"/>
                <div className="container">
                    <h1 className="page-heading single-col-max mx-auto">Powerful & ready-to-use features</h1>
                    <div className="page-intro single-col-max mx-auto">
                        Everything you need to store and manage your data!
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="item py-5 mb-5">
                    <Feature title={KeyValue.title}
                             description={KeyValue.shortDescription}
                             image={KeyValue.image}
                             docURL={KeyValue.docsURL}/>

                    <Feature title={Collections.title}
                             description={Collections.shortDescription}
                             image={Collections.image}
                             docURL={Collections.docsURL}/>

                    <Feature title={PublicRead.title}
                             description={PublicRead.shortDescription}
                             image={PublicRead.image}
                             docURL={PublicRead.docsURL}/>

                    <Feature title={PublicWrite.title}
                             description={PublicWrite.shortDescription}
                             image={PublicWrite.image}
                             docURL={PublicWrite.docsURL}/>

                    <Feature title={WebHook.title}
                             description={WebHook.shortDescription}
                             image={WebHook.image}
                             docURL={WebHook.docsURL}/>

                    <Feature title={CustomerSupport.title}
                             description={CustomerSupport.shortDescription}
                             image={CustomerSupport.image}/>
                </div>
            </div>
        </>
    );
}