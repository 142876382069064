import axios from "./initAxios";

const get = (collection, item) => {
    return axios.get(`/collections/${collection}/items/${item}`)
        .then(response =>
            response.data
        );
};

const put = (collection, item, value) => {
    return axios.put(`/collections/${collection}/items/${item}`, value, {
        headers: {
            "Content-Type": "text/plain"
        }
    })
};

export const itemClientAPI = {
    get,
    put
};