import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Alert} from "react-bootstrap";
import {Link} from "react-router-dom";
import {publicServices} from "../../services";
import Editor from "rich-markdown-editor";
import StringUtils from "../../utils/StringUtils";

export default (props) => {

    const {collection, name} = props;
    const [asyncHandler, setAsyncHandler] = useState(false);
    const [item, setItem] = useState();
    const [error, setError] = useState();

    const user = useSelector(i => {
        return i.user || {};
    });

    useEffect(() => {
        if (!asyncHandler || asyncHandler !== name) {
            setAsyncHandler(name);
            publicServices.get(process.env.REACT_APP_PUBLIC_STORAGE_UUID, collection, name)()
                .then(item => {
                        item.rnd = Math.random();
                        setItem(item);
                    },
                    e =>
                        setError(e.data.message)
                );
        }
    }, [asyncHandler, collection, item, name]);

    if (item) {
        const {value, updated_at} = item;
        const {title, description} = JSON.parse(value);
        const hasAPIKey = description.indexOf("{API_KEY}") > -1;
        const hasStoragesUUID = description.indexOf("{STORAGE_UUID}") > -1;
        const apiKey = user.api_key || "login-and-put-your-API-key-here";
        const storagesUUID = user.storage_uuid || "login-and-put-your-storage-uuid-here";
        const apiURL = process.env.REACT_APP_API_BASE_URL;
        const descriptionWithRunTime = description
            .replace(/{API_KEY}/g, apiKey)
            .replace(/{API_URL}/g, apiURL)
            .replace(/{STORAGE_UUID}/g, storagesUUID);

        return (
            <article className="docs-article">
                <header className="docs-header">
                    <h1 className="docs-heading">
                        {title}
                        {updated_at &&
                        <span className="docs-time">Last updated: {StringUtils.convertDate(updated_at)}</span>
                        }
                    </h1>
                    {hasAPIKey && !user.api_key &&
                    <Alert variant={"info"} className={"small"}>
                        Note: the 'login-and-put-your-API-key-here' occurrences will be replaced with your own
                        API key once <Link to={"/login"}>logged</Link>!
                    </Alert>
                    }
                    {hasStoragesUUID && !user.storage_uuid &&
                    <Alert variant={"info"} className={"small"}>
                        Note: the 'login-and-put-your-storage-uuid-here' occurrences will be replaced with your own
                        storage UUID once <Link to={"/login"}>logged</Link>!
                    </Alert>
                    }
                    <section className="docs-intro">
                        <Editor key={item.rnd} className={"markdown"}
                                defaultValue={descriptionWithRunTime || "n.a."}
                                readOnly onChange={() => {
                        }}>
                        </Editor>
                    </section>
                </header>
            </article>
        );
    }

    return (
        <>
            {!error && "Please wait, loading..."}
            {error &&
            <Alert>
                {error}
            </Alert>
            }
        </>);

}