import {userClientAPI} from "../api";
import {usersActions} from "../actions";

const register = (email, password) => {
    return () =>
        userClientAPI.register(email, password);
};

const authenticate = (email, password) => {
    return (dispatch) =>
        userClientAPI.authenticate(email, password)
            .then(accessToken => {
                    return accessToken;
                },
                error => {
                    throw error;
                }
            );
};

const me = () => {
    return (dispatch) =>
        userClientAPI.me()
            .then(user => dispatch(usersActions.meSuccess(user)));
};

const validateSecurityToken = (securityChallengesUUID, securityToken) => {
    return () =>
        userClientAPI.validateSecurityToken(securityChallengesUUID, securityToken)
};

const sendReset = (email) => {
    return () =>
        userClientAPI.sendReset(email)
};

const resetPassword = (securityChallengesUUID, securityToken, password) => {
    return () =>
        userClientAPI.resetPassword(securityChallengesUUID, securityToken, password)
};

const updateApiKey = (apiKey) => {
    return () =>
        userClientAPI.updateApiKey(apiKey)
}

export const userServices = {
    authenticate,
    me,
    register,
    sendReset,
    resetPassword,
    updateApiKey,
    validateSecurityToken
};