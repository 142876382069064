import React from "react";
import Typewriter from "typewriter-effect";
import PromoImg from "../img/promo-figure-alt.svg";
import MonitorLoadingProgressImg from "../img/monitor-loading-progress.svg";
import MonitorWindowImg from "../img/monitor-window.svg";
import BenefitCard from "../components/BenefitCard";
import {Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {faArrowAltCircleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProgrammerImg from "../img/programmer-male.svg";

export default () => {
    return (
        <>
            <section className="hero-section py-3 py-md-5">
                <Container>
                    <Row>
                        <div className="col-12 col-lg-6 pt-3 mb-5 mb-lg-0">
                            <h1 className="site-headline font-weight-bold mb-3">The simple storage service</h1>
                            <div className="site-tagline mb-4">The easiest and quickest way to
                                store data on the Internet when you
                                <Typewriter wrapperClassName="a" options={{
                                    strings: [
                                        'publish with GitHub Pages.',
                                        'read sensors with Arduino.',
                                        'share configurations between micro-services.',
                                        'log events.'
                                    ],
                                    wrapperClassName: "text-primary font-weight-bold",
                                    cursorClassName: "text-primary font-weight-bold",
                                    autoStart: true,
                                    loop: true,
                                }}/>
                                <div className={"mt-3"}>
                                    <small>(<i>according to our survey conducted across the whole universe</i>)</small>
                                </div>
                            </div>
                            <div className="cta-btns mb-lg-3">
                                <Link className="btn btn-primary mr-2  mb-3" to="/pricing">Get Started Free
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} className="fa-w-16 ml-2"/>
                                </Link>
                                <Link className="btn btn-secondary mb-3" to="/documentation">View Docs
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} className="fa-w-16 ml-2"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 text-center">
                            <img className="hero-figure mx-auto" src={PromoImg} alt=""/>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="benefits-section theme-bg-light py-5">
                <Container>
                    <h3 className="mb-2 text-center font-weight-bold section-title">Made For Humans</h3>
                    <div className="mb-5 text-center section-intro">
                        Developers, Makers, Learners, Bloggers, Curious or Hackers
                    </div>
                    <Row>
                        <BenefitCard icon="shapes" title={"Super Simple"} learnMoreURL={"/documentation"}>
                            Storing and retrieving data is easy as writing a single line of code in your preferred
                            language
                        </BenefitCard>
                        <BenefitCard icon="angle-double-right" title={"Plug & Play"} learnMoreURL={"/features"}>
                            Just signup and you're ready to go! No need to learn database stuff, install servers in
                            the Cloud or become a Master of Internet security
                        </BenefitCard>
                        <BenefitCard icon="dollar-sign" title={"Suit Every Pocket"} learnMoreURL={"/pricing"}>
                            We offer a wide range of plans, from the <b>forever-free plan</b> for the
                            hobbyist to the fully-fledged one for Professionals!
                        </BenefitCard>
                        <BenefitCard icon="book" title={"Documented"} learnMoreURL={"/documentation"}>
                            Read the docs and try our examples to quickly and easily store your stuff like a "data hero"!
                        </BenefitCard>
                    </Row>
                </Container>
            </section>

            <section className="how-section py-5">
                <Container>
                    <h3 className="mb-2 text-center font-weight-bold section-title">How Does It Work</h3>
                    <div className="mb-5 text-center section-intro">You're only a few simple steps away</div>

                    <Row>
                        <div className="item col-12 col-md-4">
                            <div className="icon-holder">
                                <img src={MonitorLoadingProgressImg} alt=""/>
                                <div className="arrow-holder d-none d-lg-inline-block"/>
                            </div>
                            <div className="desc p-3">
                                <h5><span className="step-count mr-2">1</span>Create your account</h5>
                                <p>
                                    <Link to={"/signup"}>Sign up</Link> the service and get access
                                    to the "<b>forever-free</b>" plan!
                                    Don't worry if your needs grow, because you may always upgrade later... :-)
                                </p>
                            </div>
                        </div>
                        <div className="item col-12 col-md-4">
                            <div className="icon-holder">
                                <img src={MonitorWindowImg} alt=""/>
                                <div className="arrow-holder d-none d-lg-inline-block"/>
                            </div>
                            <div className="desc p-3">
                                <h5><span className="step-count mr-2">2</span>Look at the examples</h5>
                                <p>
                                    Are you impatient? Jump to our <Link
                                    to={"/documentation/quickstart"}>quickstart</Link>!
                                    Or instead, are you a fast ninja willing to master the secret arts of storing?
                                    Read the <Link to={"/documentation"}>documentation</Link>.
                                </p>
                            </div>
                        </div>
                        <div className="item col-12 col-md-4">
                            <div className="icon-holder">
                                <img src={ProgrammerImg} alt=""/>
                            </div>
                            <div className="desc p-3">
                                <h5><span className="step-count mr-2">3</span>... and go!</h5>
                                <p>
                                    Start collecting and organizing your data, access your <Link to={"/login"}>Dashboard</Link>&nbsp;
                                    to check your usage and manage your account. Discover the whole set of <Link to={"/features"}>features</Link>&nbsp;
                                    available "out-of-the-box"!
                                </p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}