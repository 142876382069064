import Cookies from 'js-cookie';

const setAuthenticationToken = function (accessToken, rememberLogin = false) {
    Cookies.set('access_token', accessToken, (rememberLogin) ? {expires: 30} : {})
};

const isAuthenticated = function () {
    return Cookies.get('access_token');
};

const deauthenticate = function () {
    Cookies.remove('access_token');
};

export {
    isAuthenticated,
    deauthenticate,
    setAuthenticationToken,
}