import React from "react";
import {useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";

export default () => {

    const plan = useSelector(state => {
        return state.plans.subscription || {};
    });

    return (
        <footer className={"footer"}>
            <div className="container py-5 mb-3">
                <Row>
                    <Col sm="2" className={"footer-col"}>
                        <h4 className="col-heading">Product</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to={"/features"}>Features</Link>
                            </li>
                            <li>
                                <Link to={"/pricing"}>Pricing</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col sm="2" className={"footer-col"}>
                        <h4 className="col-heading">Resources</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to={"/documentation"}>Documentation</Link>
                            </li>
                            <li>
                                <Link to={"/openapi"}>API endpoints</Link>
                            </li>
                            <li>
                                {plan.has_support &&
                                <a href={"https://kvstore.freshdesk.com/support/home"}>Customer Support</a>
                                }
                            </li>
                        </ul>
                    </Col>
                    <Col sm="4"/>
                    <Col sm="4" className={"text-right small"}>
                        <p>
                            Made with <FontAwesomeIcon icon={faHeart} color={"red"} className={"ml-1 mr-1"}/> on Earth<br/>
                        </p>
                        <p>
                            &copy; 2020 by KVStore.io<br/>
                            VAT #IT08574700962<br/>
                            Milan - Italy
                        </p>
                    </Col>
                </Row>
            </div>
        </footer>
    )
}