import React from "react";

export default () => {

    return (
        <span className="logo-text">KV
            <span className="text-alt">
                Store
            </span>
        </span>
    );

}