import React from "react";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

export default (props) => {

    const {icon, title, description, URL} = props;

    return (
        <Card className="shadow-sm">
            <Card.Body>
                <h5 className="card-title mb-3">
                    {icon &&
                    <span className="theme-icon-holder card-icon-holder mr-2">
                        <FontAwesomeIcon icon={icon} className={"fa-w-16"}/>
                    </span>
                    }
                    <span className="card-title-text">{title}</span>
                </h5>
                <Card.Text>
                    {description}
                </Card.Text>
                <Link className="card-link-mask" to={"/documentation" + URL}/>
            </Card.Body>
        </Card>
    );

}