import React, {useEffect, useState} from 'react';
import {Button, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamation} from "@fortawesome/free-solid-svg-icons";

export default (props) => {

    const {className, disabled, variant, timeout, onClick} = props;

    const [status, setStatus] = useState("idle");
    const [running, setRunning] = useState(false);
    const [timeoutHandler, setTimeoutHandler] = useState();

    const clickButton = () => {
        const accept = () => {
            setStatus("done");
            setRunning(false);

            if (timeout) {
                setTimeoutHandler(setTimeout(() => {
                    setStatus("idle");
                }, timeout));
            }
        };

        const reject = (error) => {
            setStatus("error");
            setRunning(false);
        };

        if (onClick) {
            setStatus("pending");
            setRunning(true);
            onClick(accept, reject);
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutHandler) {
                clearTimeout(timeoutHandler);
            }
        }
    }, [timeoutHandler]);

    return (
        <Button variant={variant || "primary"}
                className={"async-button " + ((className) ? className + " " : "") + status}
                disabled={disabled || running || status === "done" || false}
                onClick={clickButton}>
            {props.children}
            {
                status === "pending" &&
                <Spinner animation="border" size="sm"/>
            }
            {
                status === "done" &&
                <FontAwesomeIcon icon={faCheck} className="ml-2 mb-1"/>
            }
            {
                status === "error" &&
                <FontAwesomeIcon icon={faExclamation} className="ml-2 mb-1"/>
            }
        </Button>
    );

}