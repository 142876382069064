import React from "react";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {deauthenticate, isAuthenticated} from "../utils/auth_utils";
import LogoText from "./LogoText";
import Logo from "./Logo";
import {useSelector} from "react-redux";

export default () => {

    const user = useSelector(i => {
        return i.user || {};
    });

    const logout = () => {
        deauthenticate();
        window.location.reload();
    };

    return (
        <Navbar bg="" variant="" fixed={"top"} className={"header"} expand={"lg"}>
            <div className={"site-logo"}>
                <Link to={"/"}>
                    <Navbar.Brand>
                        <Logo/>
                        <LogoText/>
                    </Navbar.Brand>
                </Link>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
                <span/>
                <span/>
                <span/>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto" as={"ul"}>
                    <Nav.Item as={"li"} className={"mr-lg-4"}>
                        <Link to="/features" className={"nav-link"}>Features</Link>
                    </Nav.Item>
                    <Nav.Item as={"li"} className={"mr-lg-4"}>
                        <Link to="/documentation" className={"nav-link"}>Documentation</Link>
                    </Nav.Item>
                    {!isAuthenticated() &&
                    <>
                        <Nav.Item as={"li"} className={"mr-lg-4"}>
                            <Link to="/pricing" className={"nav-link"}>Pricing</Link>
                        </Nav.Item>
                        <Nav.Item as={"li"} className={"mr-lg-4"}>
                            <Link to="/login" className={"nav-link"}>Login</Link>
                        </Nav.Item>
                        <Nav.Item as={"li"} className={"mr-lg-4"}>
                            <Link to="/signup" className={"nav-link btn btn-primary text-white"}>Signup</Link>
                        </Nav.Item>
                    </>
                    }
                    {isAuthenticated() &&
                    <>
                        <div hidden={!user.email}>
                            <NavDropdown title={user.email || ""} id="basic-nav-dropdown"
                                         className={"dropdown-menu-right rounded"}>
                                <NavDropdown.Item className={"nav-link"} onClick={logout}>
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    );

}