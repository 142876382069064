import React, {useEffect} from "react";
import {Button, Card, ListGroup, ProgressBar} from "react-bootstrap";
import FeatureRow from "../features/FeatureRow";
import {planServices} from "../../services";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import StringUtils from "../../utils/StringUtils";

export default () => {

    const dispatch = useDispatch();

    const plan = useSelector(state => {
        return state.plans.subscription;
    });

    useEffect(() => {
        if (!plan) {
            planServices.mySubscription()(dispatch);
        }

    }, [plan, dispatch]);

    return (
        <>
            {!plan &&
            <p>It looks you still don't have subscribed any plan?
                <Link to={"/pricing"}>
                    <Button size={"sm"} variant={"primary"} className={"m-2"}>Please choose one</Button>
                </Link>
            </p>
            }
            {plan &&
            <>
                <h6>
                    Your current plan
                </h6>
                <Card className={"plan-header"} bg={"light"}>
                    <Card.Header className={"bg-white"}>
                        <div className={"d-flex align-items-center"}>
                            <div className="flex-grow-1"><b>{plan.plan || "FREE"}</b></div>
                            <div>
                                <Link to={"/pricing"}>
                                    <Button size={"sm"} variant={"primary"}>Upgrade</Button>
                                </Link>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body className={"bg-white"}>
                        <Card.Text>
                            Check out your usage indicators against your current plan limits!
                        </Card.Text>
                    </Card.Body>
                    <ListGroup variant="flush" className={"small"}>
                        <ListGroup.Item>
                            <FeatureRow
                                name={`Collections (max. ${StringUtils.formatNumber(plan.max_num_collections)})`}>
                                <ProgressBar>
                                    <ProgressBar
                                        variant={plan.collections > plan.max_num_collections * .8 ? "danger" : "warning"}
                                        now={plan.collections}
                                        max={plan.max_num_collections}
                                        label={StringUtils.formatNumber(plan.collections)}/>
                                    <ProgressBar variant="success"
                                                 now={plan.max_num_collections - plan.collections}
                                                 max={plan.max_num_collections}
                                                 label={StringUtils.formatNumber(plan.max_num_collections - plan.collections)}/>

                                </ProgressBar>
                            </FeatureRow>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FeatureRow name={`Storable values (max. ${StringUtils.formatNumber(plan.max_values)})`}>
                                <ProgressBar>
                                    <ProgressBar variant={plan.values > plan.max_values * .8 ? "danger" : "warning"}
                                                 max={plan.max_values}
                                                 now={plan.values}
                                                 label={StringUtils.formatNumber(plan.values)}/>
                                    <ProgressBar variant="success"
                                                 max={plan.max_values}
                                                 now={plan.max_values - plan.values}
                                                 label={StringUtils.formatNumber(plan.max_values - plan.values)}/>
                                </ProgressBar>
                            </FeatureRow>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FeatureRow name={`Max value size: ${plan.max_value_size} bytes`}/>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FeatureRow name={plan.can_set_webhook ? "Webhooks" : "No webhooks"}/>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </>
            }
        </>
    );

}