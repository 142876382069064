import axios from "axios";
import {deauthenticate, isAuthenticated} from "../utils/auth_utils";

let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000
});


axiosInstance.interceptors.request.use(function (config) {
    const token = isAuthenticated();
    if (token) {
        config.headers.Authorization = "Bearer " + token;
    } else {
        delete config.headers.Authorization;
    }
    return config;
});

axiosInstance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        if (!error.response) {
            return Promise.reject({data: {error_code: 9999, error_message: error.message}});
        } else if (error.response && error.response.status === 401) {
            deauthenticate();
        }
        return Promise.reject(error.response);
    }
);

export default axiosInstance;