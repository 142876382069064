import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Editor from "rich-markdown-editor";

export default (props) => {

    const {title, description, docURL, image} = props;

    return (
        <div className="media mb-5">
            <img className="mr-3" src={require("../../img/" + image)} alt=""/>
            <div className="media-body">
                <h5 className="mt-0">{title}</h5>
                <Editor className={"markdown"}
                        defaultValue={description || "n.a."}
                        readOnly onChange={() => {
                }}/>
                {docURL &&
                <div className="cta-link mt-3">
                    <Link className="btn btn-light" to={"/documentation" + docURL}>
                        View Docs
                        <FontAwesomeIcon icon={faArrowAltCircleRight} className={"ml-2"}/>
                    </Link>
                </div>
                }
            </div>
        </div>
    )

}