import React, {Component} from 'react';
import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent, options = {}) {
    const trackPage = (page) => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            ReactGA.set({
                page,
                ...options
            });
            ReactGA.pageview(page);
        }
    };

    return class extends Component {
        componentDidMount() {
            const {
                location: {pathname: page}
            } = this.props;
            trackPage(page);
        }

        // eslint-disable-next-line camelcase
        UNSAFE_componentWillReceiveProps(nextProps) {
            const {
                location: {pathname: currentPage}
            } = this.props;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
}