import moment from "moment";

const convertDate = (secs) =>
    moment((secs || 0) * 1000).format('MMMM Do YYYY, h:mm:ss a');

const formatNumber = (num) =>
    num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

export default {
    convertDate,
    formatNumber
}