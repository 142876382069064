import {usersConstants} from '../constants';

export function user(state = {}, action) {

    let newState = {...state};

    switch (action.type) {
        case usersConstants.ME_SUCCESS:
            newState = Object.assign(newState, {...action.user});
            break;

        default:
            break;
    }

    return newState;

}