import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {planServices} from "../../services";
import {useDispatch, useSelector} from "react-redux";
import AsyncButton from "../buttons/AsyncButton";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

export default (props) => {

    const {show, onClose, plan, planLength} = props;

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const [fullName, setFullName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [country, setCountry] = useState("");
    const [last4, setLast4] = useState("");
    const [expMonth, setExpMonth] = useState("");
    const [expYear, setExpYear] = useState("");

    const subscription = useSelector(state => state.plans.subscription);

    useEffect(() => {
        if (subscription) {
            setFullName(subscription.billing_full_name || "");
            setAddress(subscription.billing_address || "");
            setCity(subscription.billing_city || "");
            setZipCode(subscription.billing_zip_code || "");
            setCountry(subscription.billing_country || "");
            setLast4(subscription.stripe_card_last_4 || "");
            setExpMonth(subscription.stripe_card_exp_month || "");
            setExpYear(subscription.stripe_card_exp_year || "");
        }
    }, [subscription]);

    const [error, setError] = useState();

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleCardChange = (e) => {
        if (e.error) {
            setError(e.error.message);
        } else {
            setError(false);
        }
    };

    const isFormValid = () => {
        const valid = !error &&
            fullName !== "" &&
            address !== "" &&
            city !== "" &&
            zipCode !== "" &&
            country !== "";
        return valid;
    };

    const handleSubmit = async (accept, reject) => {
        let tokenId;
        if (!last4) {
            const card = elements.getElement(CardElement);
            const resp = await stripe.createToken(card);
            if (resp.error) {
                setError(resp.error.message);
                reject();
                return;
            } else {
                setError(null);
                tokenId = resp.token.id;
            }
        }

        const priceId = (planLength === "month") ? plan.monthly_stripe_id : plan.yearly_stripe_id;

        return planServices.subscribe({fullName, address, city, zipCode, country, priceId, tokenId})(dispatch)
            .then(() => {
                    accept();
                    planServices.mySubscription()(dispatch);
                    handleClose();
                }, (err) =>
                    reject(err)
            );
    };

    return (
        <Modal show={show} onHide={handleClose} centered size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>Billing details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="small" onSubmit={handleSubmit}>
                    <Form.Label>
                        <h5>Billing information</h5>
                    </Form.Label>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Full name
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" value={fullName} onChange={(e) => setFullName(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Address
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            City
                        </Form.Label>
                        <Col sm="2">
                            <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)}
                            />
                        </Col>

                        <Form.Label column sm="2">
                            Zip Code
                        </Form.Label>
                        <Col sm="2">
                            <Form.Control type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)}
                            />
                        </Col>
                        <Form.Label column sm="2">
                            Country
                        </Form.Label>
                        <Col sm="2">
                            <Form.Control type="text" value={country} onChange={(e) => setCountry(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Label>
                        <h5>Payment</h5>
                    </Form.Label>
                    <Alert variant={"light"}>
                        <b>Security note:</b> your credit card and payments are granted by&nbsp;
                        <a href={"https://stripe.com"} target={"_blank"} rel="noopener noreferrer">Stripe.com</a>.&nbsp;
                        <u>
                            Hence KVStore.io never receives, handles, or stores your credit card, or processes any
                            financial transaction involving your card.
                        </u>
                    </Alert>
                    <Form.Group>
                        <Form.Label>
                            Credit or debit card
                        </Form.Label>
                        {!last4 &&
                        <>
                            <CardElement
                                id="card-element"
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={handleCardChange}
                            />
                            {error &&
                            <Alert variant={"danger"} className={"m-2"}>{error}</Alert>
                            }
                        </>
                        }
                        {last4 &&
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                Last 4 digits
                            </Form.Label>
                            <Form.Label column sm="2">
                                {last4}
                            </Form.Label>
                            <Form.Label column sm="4">
                                Expiration (month/year)
                            </Form.Label>
                            <Form.Label column sm="2">
                                {expMonth}/{expYear}
                            </Form.Label>
                        </Form.Group>
                        }
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <AsyncButton type="submit" size={"sm"} onClick={handleSubmit} disabled={!isFormValid()}>
                    Enroll plan</AsyncButton>
            </Modal.Footer>
        </Modal>
    );

}