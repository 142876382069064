import React from "react";

export default (props) => {

    const {name, description, monthly, yearly, showPlan, cta} = props;

    return (
        <div className="item shadow rounded">
            <div className="plan-header text-center">
                <h3 className="plan-title">{name}</h3>
                <div className="plan-cost">
                    <div className="plan-cost-monthly" style={{display: (showPlan === 'month') ? "" : "none"}}>
                        <span className="currency">€</span>
                        <span className="number">{monthly || 0}</span>
                        <span className="unit">/month</span>
                    </div>
                    <div className="plan-cost-yearly" style={{display: (showPlan === 'year') ? "" : "none"}}>
                        <span className="currency">€</span>
                        <span className="number">{yearly || 0}</span>
                        <span className="unit">/year</span>
                    </div>
                </div>
                <div className="plan-cta">
                    {cta}
                </div>
            </div>
            <div className="plan-content">
                <div className="plan-intro">
                    {description}
                </div>
                {props.children &&
                <ul className="plan-desc list-unstyled">
                    {React.Children.map(props.children, (child, index) =>
                        <li>
                            {child}
                        </li>
                    )}
                </ul>
                }
            </div>
        </div>
    );
}