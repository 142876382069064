import React from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {Container, Navbar} from "react-bootstrap";
import Logo from "../components/Logo";
import LogoText from "../components/LogoText";
import * as queryString from 'query-string';
import {userServices} from "../services";
import AsyncButton from "../components/buttons/AsyncButton";

export default () => {

    const location = useLocation();
    const history = useHistory();

    const {security_challenges_uuid, security_token} = queryString.parse(location.search);

    const parametersValid = () => {
        return security_challenges_uuid !== undefined && security_token !== undefined
    };

    const completeRegistration = (accept, reject) => {
        userServices.validateSecurityToken(security_challenges_uuid, security_token)()
            .then(() => {
                accept();
                history.push("/login")
            }, (err) => {
                reject(err);
            });
    };

    return (
        <section className="auth-section text-center py-5">
            <Container>
                <div className="site-logo mb-4">
                    <Link to={"/"}>
                        <Navbar.Brand>
                            <Logo/>
                            <LogoText/>
                        </Navbar.Brand>
                    </Link>
                </div>

                <div className="auth-wrapper mx-auto shadow p-5 rounded">
                    <h2 className="auth-heading text-center mb-4">Welcome to your new storage!</h2>

                    <div className="auth-intro mb-4 text-center">
                        Click the button here below to complete the registration
                    </div>

                    {parametersValid() &&
                    <div className="text-center">
                        <AsyncButton onClick={completeRegistration}
                                     className="btn btn-primary btn-block theme-btn mx-auto">
                            <span>Complete Account</span>
                        </AsyncButton>
                    </div>
                    }

                </div>
            </Container>
        </section>
    )

}