import React, {useState} from "react";
import Logo from "../components/Logo";
import LogoText from "../components/LogoText";
import {Link} from "react-router-dom";
import {userServices} from "../services";
import {Navbar} from "react-bootstrap";
import AsyncButton from "../components/buttons/AsyncButton";

export default () => {

    const [email, setEmail] = useState("");
    const [showHomeLink, setShowHomeLink] = useState(false);

    const isResetDisabled = () => {
        return email === "" || showHomeLink;
    };

    const sendReset = (accept, reject) => {
        return userServices.sendReset(email)()
            .then(() => {
                if (accept) {
                    accept();
                }
            })
            .catch(e => {
                if (reject) {
                    reject();
                }
            })
            .finally(() =>
                setShowHomeLink(true)
            );
    };

    return (

        <section className="auth-section password-reset-section text-center py-5">
            <div className="container">

                <div className="site-logo mb-4">
                    <Link to={"/"}>
                        <Navbar.Brand>
                            <Logo/>
                            <LogoText/>
                        </Navbar.Brand>
                    </Link>
                </div>

                <div className="auth-wrapper mx-auto shadow p-5 rounded">
                    <h2 className="auth-heading text-center mb-4">Password Reset</h2>

                    <div className="auth-intro mb-4 text-center">
                        Enter your email address below. We'll email you a link
                        to a page where you can easily create a new password.
                    </div>

                    <div className="auth-form-container text-left">

                        <div className="auth-form resetpass-form">
                            <div className="form-group email">
                                <label className="sr-only" htmlFor="reg-email">Your Email</label>
                                <input id="reg-email"
                                       name="reg-email"
                                       type="email"
                                       className="form-control login-email"
                                       placeholder="Your Email"
                                       onChange={(v) => setEmail(v.target.value)}
                                       required="required"/>
                            </div>
                            <div className="text-center">
                                <AsyncButton className="btn-block theme-btn mx-auto"
                                             onClick={sendReset}
                                             disabled={isResetDisabled()}>
                                    {!showHomeLink &&
                                    <span>
                                        Reset Password
                                    </span>
                                    }
                                    {showHomeLink &&
                                    <span>
                                        Check your inbox and follow instructions
                                    </span>
                                    }
                                </AsyncButton>
                            </div>
                        </div>
                        <div className="auth-option text-center pt-5">
                            <Link to="/login">Return to login</Link>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}