import React from "react";
import {Container, Nav} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import {Link, useParams} from "react-router-dom";
import Logo from "../components/Logo";
import LogoText from "../components/LogoText";
import DocArticle from "../components/documentation/DocArticle";

export default () => {

    let {doc} = useParams();

    return (
        <div className="docs-wrapper">
            <Navbar bg="" variant="" fixed={"top"} className={"header"} expand={"lg"}>
                <div className={"site-logo"}>
                    <Link to={"/"}>
                        <Navbar.Brand>
                            <Logo/>
                            <LogoText/>
                        </Navbar.Brand>
                    </Link>
                </div>
                <Navbar.Toggle>
                    <span/>
                    <span/>
                    <span/>
                </Navbar.Toggle>
                <Navbar.Collapse id="doc-navbar-collapse">
                    <Nav className="ml-auto" as={"ul"}>
                        <Nav.Item as={"li"} className={"mr-lg-4"}>
                            <Link className={"nav-link"} to={"/documentation"}>Docs home</Link>
                        </Nav.Item>
                        <Nav.Item as={"li"} className={"mr-lg-4"}>
                            <Link className={"nav-link"} to={"/documentation/quickstart"}>Quickstart</Link>
                        </Nav.Item>
                        <Nav.Item as={"li"} className={"mr-lg-4"}>
                            <Link className={"nav-link"} to={"/documentation/introduction"}>Introduction</Link>
                        </Nav.Item>
                        <Nav.Item as={"li"} className={"mr-lg-4"}>
                            <Link className={"nav-link"} to={"/documentation/api"}>API</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div>
                <Container>
                    <DocArticle collection="documentation" name={doc}/>
                </Container>
            </div>
        </div>
    )
}