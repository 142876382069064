import {planClientAPI} from "../api";
import {plansActions} from "../actions/plans.actions.factory";

const list = () => {
    return (dispatch) =>
        planClientAPI.list()
            .then(plans => {
                dispatch(plansActions.listSuccess(plans));
                return plans;
            })
};

const mySubscription = () => {
    return (dispatch) =>
        planClientAPI.mySubscription()
            .then(subscription => {
                dispatch(plansActions.mySubscription(subscription));
                return subscription;
            })
};

const subscribe = ({fullName, address, city, zipCode, country, priceId, tokenId}) => {
    return () =>
        planClientAPI.subscribe({fullName, address, city, zipCode, country, priceId, tokenId})
};

export const planServices = {
    list,
    subscribe,
    mySubscription
};