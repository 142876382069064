import axios from "./initAxios";

const register = (email, password) => {
    return axios.post("/users", {
        email,
        password
    });
};

const authenticate = (email, password) => {
    return axios.post("/users/login", null, {
        auth: {
            username: email,
            password: password
        }
    }).then(response => response.data);
};

const validateSecurityToken = (securityChallengesUUID, securityToken) => {
    return axios.put(`/users/${securityChallengesUUID}`, null, {
        params: {
            "security-token": securityToken
        }
    })
};

const sendReset = (email) => {
    return axios.post("/users/send-reset", null, {
        params: {
            email
        }
    })
};

const resetPassword = (securityChallengesUUID, securityToken, password) => {
    return axios.put(`/users/reset-password/${securityChallengesUUID}`, {
        password
    }, {
        params: {
            "security-token": securityToken
        }
    })
};

const me = () => {
    return axios.get("/users/me")
        .then(response =>
            response.data
        );
};

const updateApiKey = (apiKey) => {
    return axios.put("/users/update-api-key", {
        api_key: apiKey
    });
}

export const userClientAPI = {
    authenticate,
    me,
    register,
    sendReset,
    resetPassword,
    updateApiKey,
    validateSecurityToken
};