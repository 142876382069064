import {plansConstants} from '../constants';

export function plans(state = {}, action) {

    const newState = {...state};

    switch (action.type) {
        case plansConstants.LIST_SUCCESS:
            newState.items = action.plans;
            break;

        case plansConstants.MY_SUBSCRIPTION_SUCCESS:
            newState.subscription = action.subscription;
            break;

        default:
            break;
    }

    return newState;

}