import {itemClientAPI} from "../api";

const get = (collection, item) => {
    return () =>
        itemClientAPI.get(collection, item)
}

const put = (collection, item, value) => {
    return () =>
        itemClientAPI.put(collection, item, value)
}

export const itemServices = {
    get,
    put
};