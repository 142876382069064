import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {itemServices} from "../services";
import {Container, Row} from "react-bootstrap";
import Editor from "rich-markdown-editor";
import AsyncButton from "./buttons/AsyncButton";
import StringUtils from "../utils/StringUtils";

export default (props) => {

    const {collection, item} = props;

    const user = useSelector(i => i.user || {});
    const [title, setTitle] = useState();
    const [shortDescription, setShortDescription] = useState();
    const [description, setDescription] = useState();
    const [asyncHandler, setAsyncHandler] = useState();

    const [createdAt, setCreatedAt] = useState();
    const [updatedAt, setUpdatedAt] = useState();

    useEffect(() => {

        if (!asyncHandler) {
            setAsyncHandler(true);
            itemServices.get(collection, item)()
                .then(v => v,
                    (e) => {
                        return {};
                    })
                .then(v => {
                    try {
                        const value = JSON.parse(v.value || "{}");
                        setTitle(value.title);
                        setShortDescription(value.shortDescription)
                        setDescription(value.description || "Write here...");
                        if (v.created_at) {
                            setCreatedAt(StringUtils.convertDate(v.created_at));
                        }

                        if (v.updated_at) {
                            setUpdatedAt(StringUtils.convertDate(v.updated_at));
                        }
                    } catch (e) {
                        console.log(e);
                    }
                });
        }
    }, [asyncHandler, collection, item]);

    const onChange = (value) => {
        setDescription(value());
    };

    const updateTitle = (e) => {
        setTitle(e.target.value);
    };

    const updateShortDescription = (e) => {
        setShortDescription(e.target.value);
    };

    const update = (accept, reject) => {
        const payload = {
            title,
            shortDescription,
            description
        };

        itemServices.put(collection, item, JSON.stringify(payload))()
            .then(() => accept(),
                (err) => reject(err));
    };

    return (
        <Container>
            <Row className={"mb-3 d-flex"}>
                <div className="flex-grow-1 mr-3 mb-3">
                    <div>Public URL:</div>
                    <div>{`${process.env.REACT_APP_API_BASE_URL}/storages/${user.storage_uuid}/collections/${collection}/items/${item}`}</div>
                </div>
                <AsyncButton onClick={update} timeout={5000} className={"btn-sm"}>Update</AsyncButton>
            </Row>
            <>
                <Row className={"d-flex mb-3"}>
                    {createdAt &&
                    <span className="docs-time mr-3 flex-grow-1">
                        Created : {createdAt}
                    </span>
                    }
                    {updatedAt &&
                    <span className="docs-time text-right">
                        Last updated: {updatedAt}
                    </span>
                    }
                </Row>
                <Row>
                    <div className="form-group w-100">
                        <label>Title</label>
                        <input className="form-control" defaultValue={title} onChange={updateTitle}/>
                    </div>
                </Row>
                <Row>
                    <div className="form-group w-100">
                        <label>Short description</label>
                        <input className="form-control" defaultValue={shortDescription}
                               onChange={updateShortDescription}/>
                    </div>
                </Row>
                {description &&
                <Row>
                    <div className="form-group">
                        <label>Description</label>
                        <Editor className={"w-100"}
                                defaultValue={description}
                                onChange={onChange}/>
                    </div>
                </Row>
                }
            </>
        </Container>
    );

}