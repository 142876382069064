import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default (props) => {

    const {icon, title, learnMoreURL} = props;

    return (
        <div className="item col-12 col-md-6 col-lg-3">
            <div className="item-inner rounded">
                <div className="icon-holder text-center mx-auto mb-3">
                    <FontAwesomeIcon icon={icon}/>
                </div>
                <h5 className="mb-3">{title}</h5>
                <div>
                    {props.children}
                </div>
                {learnMoreURL &&
                <div className="mt-2">
                    <Link to={learnMoreURL}>Learn more →</Link>
                </div>
                }
            </div>
        </div>
    )

}

