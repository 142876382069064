import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Popover} from "react-bootstrap";

export default (props) => {

    const {name, description} = props;

    const popover = (
        <Popover>
            <Popover.Content>
                {description}
            </Popover.Content>
        </Popover>
    );

    return (
        <>
            {name}
            {props.children}
            {description &&
            <OverlayTrigger trigger="click"
                            placement="right" overlay={popover}
                            rootClose={true}>
                <div className={"theme-icon-holder info-popover-trigger"}>
                    <FontAwesomeIcon icon={faInfo}/>
                </div>
            </OverlayTrigger>
            }
        </>
    )

}