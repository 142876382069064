import React from "react";
import {useSelector} from "react-redux";

export default () => {

    const user = useSelector(i => {
        return i.user || {};
    });

    const storageUUID = user.storage_uuid;

    const includeHTML = "<script async src=\"" + process.env.REACT_APP_API_BASE_URL + "/js/public.js?storage_uuid=" + storageUUID + "\"></script>";

    return (
        <>
            <h6>
                Javascript include
            </h6>
            <small>
                <p>
                    Add this fragment to the head section of your HTML page if using
                    the <i>public_write</i> feature.
                </p>
            </small>
            <code>
                {includeHTML}
            </code>
        </>
    );
}