import axios from "./initAxios";

const list = () => {
    return axios.get("/plans")
        .then(response =>
            response.data
        );
};

const subscribe = ({fullName, address, city, zipCode, country, priceId, tokenId}) => {
    return axios.post("/plans/subscriptions", {
        billing_full_name: fullName,
        billing_address: address,
        billing_city: city,
        billing_zip_code: zipCode,
        billing_country: country,
        stripe_price_id: priceId,
        stripe_token_id: tokenId
    });
};

const mySubscription = () => {
    return axios.get("/plans/subscriptions/mine")
        .then(response =>
            response.data
        );
};

export const planClientAPI = {
    list,
    subscribe,
    mySubscription
};