import {plansConstants} from "../constants";

function listSuccess(plans) {
    return {type: plansConstants.LIST_SUCCESS, plans}
}

function mySubscription(subscription) {
    return {type: plansConstants.MY_SUBSCRIPTION_SUCCESS, subscription}
}

export const plansActions = {
    listSuccess,
    mySubscription
};