import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import DocCard from "../components/documentation/DocCard";
import {faBox, faBullhorn, faChargingStation, faCode, faMapSigns, faPencilAlt} from "@fortawesome/free-solid-svg-icons";

export default () => {
    return (
        <div>
            <div className="page-header theme-bg-dark py-5 text-center position-relative">
                <div className="theme-bg-shapes-right"/>
                <div className="theme-bg-shapes-left"/>
                <h1 className="page-heading single-col-max mx-auto">Documentation</h1>
                <div className="page-intro single-col-max mx-auto">
                    Everything you need to start with KVStore.
                </div>
            </div>
            <div className="py-3 py-md-5">
                <Container>
                    <div className="docs-overview py-5">
                        <Row className="justify-content-center">
                            <Col lg="4" className="py-3">
                                <DocCard
                                    icon={faChargingStation}
                                    title="Quickstart"
                                    description="Quickstart for the impatient :-)"
                                    URL={"/quickstart"}/>
                            </Col>
                            <Col lg="4" className="py-3">
                                <DocCard
                                    icon={faMapSigns}
                                    title="Introduction"
                                    description="A brief introduction to what KVStore.io is and what you can do with it."
                                    URL={"/introduction"}/>
                            </Col>
                            <Col lg="4" className="py-3">
                                <DocCard
                                    icon={faBox}
                                    title="API"
                                    description="Access KVStore.io using REST API"
                                    URL={"/api"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" className="py-3">
                                <DocCard
                                    icon={faBullhorn}
                                    title="Public Read"
                                    description="Publicly distribute your content"
                                    URL={"/public-read"}/>
                            </Col>
                            <Col lg="4" className="py-3">
                                <DocCard
                                    icon={faPencilAlt}
                                    title="Public Write"
                                    description="Learn how to collect data from a static website and store to KVStore.io"
                                    URL={"/public-write"}/>
                            </Col>
                            <Col lg="4" className="py-3">
                                <DocCard
                                    icon={faCode}
                                    title="Java SDK"
                                    description="The client library for the Java language"
                                    URL={"/sdk-java"}/>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}