import axios from "./initAxios";

const getPublicItem = (storagesUUID, collection, item) => {
    return axios.get(`/storages/${storagesUUID}/collections/${collection}/items/${item}`)
        .then(response =>
            response.data
        );
};

export const publicClientAPI = {
    getPublicItem
};