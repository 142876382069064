import React from "react";
import {useParams} from "react-router-dom";
import {Container, Row} from "react-bootstrap";
import ItemMDEditor from "../components/ItemMDEditor";

export default () => {

    const {collection, item} = useParams();

    return (
        <>
            <section className="py-3 py-md-5">
                <Container>
                    <Row>
                        <ItemMDEditor collection={collection} item={item}/>
                    </Row>
                </Container>
            </section>
        </>
    );
}