import React, {useState} from "react"
import LogoText from "../components/LogoText";
import Logo from "../components/Logo";
import {Link} from "react-router-dom";
import {Navbar} from "react-bootstrap";
import {userServices} from "../services";
import AsyncButton from "../components/buttons/AsyncButton";

export default () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [registerDone, setRegisterDone] = useState(false);
    const [registerError, setRegisterError] = useState();

    const register = (accept, reject) => {
        userServices.register(email, password)()
            .then(() => {
                if (accept) {
                    accept();
                }
                setRegisterError(false);
                setRegisterDone(true);
            })
            .catch(err => {
                    if (reject) {
                        reject(err);
                    }
                    setRegisterError(err);
                }
            )
    };

    const isRegisterDisabled = () => {
        return (email === "" || password === "" || registerDone);
    };

    return (

        <section className="auth-section signup-section text-center py-5">
            <div className="container">

                <div className="site-logo mb-4">
                    <Link to={"/"}>
                        <Navbar.Brand>
                            <Logo/>
                            <LogoText/>
                        </Navbar.Brand>
                    </Link>
                </div>

                <div className="auth-wrapper mx-auto shadow p-5 rounded">
                    <h2 className="auth-heading text-center mb-3">Start Your Free plan today</h2>
                    <div className="auth-heading-desc mb-5">No credit card required</div>

                    <div className="auth-form-container text-left mx-auto">
                        <div className="auth-form auth-signup-form">

                            <div className="form-group email">
                                <label className="sr-only" htmlFor="signup-email">Your Email</label>
                                <input id="signup-email" name="signup-email"
                                       type="email"
                                       className="form-control signup-email"
                                       placeholder="Your email"
                                       onChange={(v) => setEmail(v.target.value)}
                                       required="required"/>
                            </div>

                            <div className="form-group password">
                                <label className="sr-only" htmlFor="signup-password">Password</label>
                                <input id="signup-password" name="signup-password"
                                       type="password"
                                       className="form-control signup-password"
                                       placeholder="Create a password"
                                       onChange={(v) => setPassword(v.target.value)}
                                       required="required"/>
                            </div>

                            <div className="text-center">
                                <AsyncButton
                                    onClick={register}
                                    className="btn-block theme-btn mx-auto"
                                    disabled={isRegisterDisabled()}>
                                    {!registerDone &&
                                    <span>Create Account</span>
                                    }
                                    {registerDone &&
                                    <span>Check your inbox and follow instructions</span>
                                    }
                                </AsyncButton>
                            </div>
                            {registerError &&
                            <div className={"text-center text-danger m-3"}>
                                Signup error...
                            </div>
                            }
                        </div>

                        <div className="auth-option text-center pt-5">
                            Already have an account? <Link to="/login">Log in</Link>
                        </div>
                    </div>

                </div>

            </div>
        </section>

    );
}