import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import PlanMonitor from "../components/plans/PlanMonitor";
import {Link} from "react-router-dom";
import ApiKey from "../components/ApiKey";
import JSInclude from "../components/JSInclude";

export default () => {

    return (
        <div className="home-page py-3 py-md-5">
            <Container>
                <Row className={"section"}>
                    <Col>
                        <h5>
                            Dashboard
                        </h5>
                        <p>
                            This is your private page where you can manage your configuration and setup
                            your profile and plan. For further information, please refer to the <Link
                            to={"/documentation"}>documentation</Link>.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={"8"} className={"py-3 mb-3"}>
                        <Row className={"section"}>
                            <Col>
                                <ApiKey/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <JSInclude/>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={"4"}>
                        <PlanMonitor/>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}